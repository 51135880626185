
























































/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component({ })
export default class Bind extends Vue {
  private stripe!: any;
  private card!: any;

  private userImg = '';
  private accountImg = '';
  private licenseKey = '';
  private user!: any;
  private paymentMethod = '';
  private processing = false;
  private BASE_API_URL = 'http://localhost:4200/'

  async created () {
    const discordAccess = await this.getUser()
    this.user = await this.getDiscordUser(discordAccess)
    this.accountImg = 'https://cdn.discordapp.com/attachments/713146013782639120/719863539434651699/NATIONs.png' // change => api
    // this.accountImg = 'https://www.djivyla.com/home/wp-content/uploads/2015/04/YESSIR_THE_MIXTAPE-web.jpg' // change => api

    this.userImg = 'https://cdn.discordapp.com/avatars/' + this.user.id + '/' + this.user.avatar + '.png?size=512'
    console.log('user', this.user)
    console.log('user-img', this.userImg)
  }

  mounted () {
    this.$root.$on('bv::modal::hide', (bvEvent: any, modalId: any) => {
      console.log('modal is about to be hidden')
      this.processing = false
    })
  }

  public async updatePaymentMethod (card: any, licenseKey: string) {
    const paymentMethod = await this.createPaymentMethod(card)
    const { status } = await this.activateLicense(licenseKey, paymentMethod)
    this.displayMessageInModal(status)
  }

  private async createPaymentMethod (card: any): Promise<string> {
    try {
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card
      })

      if (error) throw error
      return paymentMethod.id
    } catch (error) {
      this.displayMessageInModal(error)
      throw new Error(error)
    }
  }

  public async activateLicense (licenseKey: string, paymentMethod?: string): Promise<any> {
    try {
      if (licenseKey) {
        this.processing = true
        const res = await axios.post(this.BASE_API_URL + 'licenses/bind', {
          key: licenseKey,
          paymentMethod
        }, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        })

        if (res.status >= 300) throw res

        this.processing = false
        this.$router.push('/dashboard')
        return res.data
      }
    } catch (error) {
      console.log(error.response)
      if (error.response.status === 402) this.openUpdatePMModal(error.response.data.stripe_public_key)
      else if (error.response.data.error) this.displayError(error.response.data.error)
      else this.displayError(error.response.statusText)
    }
  }

  private async openUpdatePMModal (stripe_public_key: string) {
    this.$bvModal.show('update-payment-method-modal')

    this.stripe = window.Stripe!(stripe_public_key)
    const elements = this.stripe.elements()

    this.card = elements.create('card')
    this.card.mount('#card')

    this.card.on('change', (event: any) => {
      console.log('change!!!', event)
    })
  }

  // Error handling
  private displayError (error: any) {
    const element = document.getElementById('activate-error')
    if (element) {
      this.processing = false
      element.textContent = error
    } else console.error(error)
  }

  private displayMessageInModal (error: any) {
    const element = document.getElementById('modal-error')
    this.processing = false
    if (element) element.textContent = error
    else console.error(error)
  }

  private async getUser () {
    try {
      const { data } = await axios.get('http://localhost:4200/users', {
        withCredentials: true
      })
      if (data) { return data.discordAccess }
    } catch (e) {
      throw Error()
    }
  }

  private async getDiscordUser (discordAccess: string) {
    try {
      const { data } = await axios.get('https://discord.com/api/users/@me', {
        headers: {
          'Authorization': 'Bearer ' + discordAccess
        }
      })
      console.log('discorddata', data)
      return data
    } catch (error) {
      console.error('ALARMIMDARM', error)
    }
  }

  public async logout () {
    try {
      const res = await axios({
        method: 'post',
        url: 'http://localhost:4200/auth/log-out',
        withCredentials: true
      })
      if (res.data.status === 'success') console.log('YEEEHAW')
    } catch (e) {
      throw Error()
    }
  }
}
